import React, { Component } from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { StickyContainer, Sticky } from "react-sticky";

import "../../styles/_reset.scss";

import NavBar from "./NavBar";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";

class LayoutTemplate extends Component {
  state = {
    mobileOpen: false
  };

  toggleMobileMenuOpen = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { location = {} } = this.props;
    const { data } = this.props;
    return (
      <div>
        <Helmet>
          <html lang="no" />
          <title>{data.site.siteMetadata.title}</title>
          <script src="https://polyfill.io/v3/polyfill.min.js?features=default%2Cfetch%2CString.prototype.startsWith%2CObject.entries%2CArray.prototype.findIndex%2CSet" />
          <meta property="og:type" content="business.business" />
          <meta property="og:url" content={location.href} />
        </Helmet>
        <StickyContainer>
          <div id="top" />
          <Sticky>
            {({ style }) => (
              <nav style={{ ...style, zIndex: 10 }}>
                <NavBar
                  location={location.pathname}
                  mobileOpen={this.state.mobileOpen}
                  toggleOpen={this.toggleMobileMenuOpen}
                />
              </nav>
            )}
          </Sticky>
          <div id="main">{this.props.children}</div>
          <Footer location={location.pathname} />
        </StickyContainer>

        <MobileMenu
          toggleOpen={this.toggleMobileMenuOpen}
          mobileOpen={this.state.mobileOpen}
          location={location.pathname}
          open={this.state.mobileOpen}
        />
      </div>
    );
  }
}

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={HEADING_QUERY}
      render={data => (
        <LayoutTemplate data={data} location={location}>
          {children}
        </LayoutTemplate>
      )}
    />
  );
};

export default Layout;

const HEADING_QUERY = graphql`
  query HeadingQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
