import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { HamburgerButton } from "react-hamburger-button";
import { Transition } from "react-spring";

import styles from "./MobileMenu.module.scss";

import NavItems from "./NavItems";

const MOBILE_MENU_QUERY = graphql`
  query MobileMenuQuery {
    file(relativePath: { eq: "logo/Brillante_Logo_hvit.png" }) {
      childImageSharp {
        fixed(width: 158) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

export default function MobileMenu({ toggleOpen, mobileOpen, location, open }) {
  return (
    <StaticQuery
      query={MOBILE_MENU_QUERY}
      render={data => (
        <>
          <Transition
            items={open}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
          >
            {open =>
              open &&
              (props => (
                <div
                  style={props}
                  className={styles.backdrop}
                  onClick={toggleOpen}
                />
              ))
            }
          </Transition>

          <Transition
            items={open}
            from={{ opacity: 0, transform: "translateX(100%)" }}
            enter={{ opacity: 1, transform: "translateX(0)" }}
            leave={{ opacity: 0, transform: "translateX(100%)" }}
          >
            {open =>
              open &&
              (props => (
                <nav style={props} className={styles.mobileNav}>
                  <div className={styles.mobileMenuContent}>
                    <div className={styles.hamburger}>
                      <HamburgerButton
                        open={mobileOpen}
                        onClick={toggleOpen}
                        width={20 * 1.3}
                        height={15 * 1.3}
                        strokeWidth={3}
                        color={"rgb(245, 245, 245)"}
                        animationDuration={0.5}
                      />
                    </div>
                    <div className={styles.brand}>
                      <Img fixed={data.file.childImageSharp.fixed} />
                    </div>
                    <NavItems
                      location={location}
                      menuItemsClass={styles.menuItems}
                      currentClassName={styles.isCurrent}
                      toggleOpen={toggleOpen}
                    />
                  </div>
                </nav>
              ))
            }
          </Transition>
        </>
      )}
    />
  );
}
