import React from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";

export default function({
  location,
  menuItemsClass,
  currentClassName,
  toggleOpen
}) {
  const NavLink = ({ children, href }) => {
    return location === "/" ? (
      <AnchorLink onClick={toggleOpen} href={href} offset="30">
        {children}
      </AnchorLink>
    ) : (
      <Link to={`/${href}`}>{children}</Link>
    );
  };
  return (
    <Scrollspy
      className={menuItemsClass}
      items={["hjem", "events", "musikere", "om", "blogg", "kontakt"]}
      currentClassName={currentClassName}
      offset={-35}
    >
      <li>
        <NavLink href="#hjem">Hjem</NavLink>
      </li>
      <li>
        <NavLink href="#events">Eventer</NavLink>
      </li>
      <li>
        <NavLink href="#musikere">Musikere</NavLink>
      </li>
      <li>
        <NavLink href="#om">Om oss</NavLink>
      </li>
      <li>
        <Link to="/blogg">Blogg</Link>
      </li>
      <li>
        <NavLink href="#kontakt">Kontakt</NavLink>
      </li>
    </Scrollspy>
  );
}
