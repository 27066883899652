import React from "react";
import styles from "./Footer.module.scss";
import {
  FaFacebook,
  FaLinkedin,
  FaAngleDoubleUp,
  FaInstagram
} from "react-icons/fa";
import { IconContext } from "react-icons";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Footer({ location }) {
  const date = new Date();
  return (
    <footer className={styles.footer}>
      <div className={styles.upArrowContainer}>
        <AnchorLink
          href={location === "/" ? "#hjem" : "#top"}
          aria-label="Tilbake til toppen"
        >
          <div className={styles.upArrow}>
            <FaAngleDoubleUp />
          </div>
        </AnchorLink>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.copyright}>
          Brillante Musikerformidling DA &copy; {date.getFullYear()}{" "}
        </div>
        <div className={styles.socialMediaContainer}>
          <div className={styles.followUs}>Følg oss:</div>
          <div>
            <IconContext.Provider value={{ className: styles.icons }}>
              <a
                className={styles.instagram}
                href="https://www.instagram.com/brillante.musikerformidling/"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                className={styles.facebook}
                href="https://www.facebook.com/brillanteoslo/"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebook />
              </a>
              <a
                className={styles.linkedin}
                href="https://www.linkedin.com/company/brillante-musikerformidling-da/"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </a>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </footer>
  );
}
