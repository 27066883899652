import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./NavBar.module.scss";
import { HamburgerButton } from "react-hamburger-button";

import NavItems from "./NavItems";

class NavBar extends Component {
  state = {
    index: false
  };

  componentDidMount() {
    if (this.props.location === "/") {
      this.setState({ index: true });
    }
  }

  render() {
    return (
      <div className={`${styles.nav}`}>
        <div className={styles.navContainer}>
          <div className={styles.brand}>
            <Link to="/">
              <Img fluid={this.props.data.file.childImageSharp.fluid} />
            </Link>
          </div>
          <NavItems
            location={this.props.location}
            menuItemsClass={styles.menuItems}
            currentClassName={styles.isCurrent}
          />
          <div className={styles.hamburger}>
            <HamburgerButton
              open={this.props.mobileOpen}
              onClick={this.props.toggleOpen}
              width={20 * 1.3}
              height={15 * 1.3}
              strokeWidth={3}
              color={"rgb(245, 245, 245)"}
              animationDuration={0.5}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "brillante-logo-hvit.png" }) {
            childImageSharp {
              fluid(maxWidth: 158, maxHeight: 56) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => <NavBar data={data} {...props} />}
    />
  );
}
